<template>
  <div class="complete">
    <!--查询结果-->
    <div>
      <div class="title">
        <span>查询结果</span>
        <div>
          <el-button :disabled="isDisabled" type="primary" @click="showtQszm" size="small">查档证明</el-button>
          <!-- <el-button
            :disabled="isDisabled"
            type="primary"
            v-if="checkData.bdcxxList && checkData.isyf"
            @click="printYfqszm"
            size="small"
            >有房证明</el-button
          >-->
          <!-- <el-button
            :disabled="isDisabled"
            type="primary"
            v-if="!checkData.bdcxxList || !checkData.isyf"
            @click="printWfqszm"
            size="small"
            >无住房证明</el-button
          > -->
          <!-- <el-button :disabled="!checkData.cxbh || isDisabled" type="primary" @click="watchFJgl" size="small"
            >查看附件</el-button
          > -->
        </div>
      </div>
      <div class="remind">
        依<span class="font-bold">{{ userName }}</span
        >的申请，经查询省厅不动产统一登记信息管理系统，
        <!-- <span
          v-if="checkData.bdcxxList && checkData.bdcxxList.length > 0"
          >查档编号:{{ checkData.cxbh }}(自助查询系统)，</span
        > -->
        <span class="font-bold" v-if="!checkData.bdcxxList || checkData.bdcxxList.length < 1">暂无房产信息</span>
        <span class="font-bold" v-else>共有{{ houseNum || 0 }}套房产</span>
      </div>
      <!--有数据-->
      <e-table
        v-if="checkData.bdcxxList && checkData.bdcxxList.length > 0"
        ref="yfBdcxxList"
        :columns="columns"
        :options="options"
        :tableList="tableList"
        @currentChange="currentChangeHandle"
      ></e-table>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          layout="prev, pager, next, total,jumper"
          :total="totalNum"
        ></el-pagination>
      </div>
      <div class="jzxx">
        <el-button class="mt30" type="default" @click="backHomepage" size="small">返回大厅</el-button>
      </div>
      <!-- 查看附件弹窗 -->
      <view-attachments
        v-if="this.ckfjData.ckfjDialogVisible"
        :ckfjData="ckfjData"
        :fileImgUrl="fileImgUrl"
        @changeckfjData="changeckfjData"
      ></view-attachments>
      <el-dialog title="权属证明预览" :visible.sync="previewVisiable" width="70%" @close="previewVisiable = false">
        <div v-html="qszmHtml"></div>
        <span slot="footer">
          <el-button @click="verifyQszm">对此结果有异议</el-button>
          <el-button type="primary" @click="printQszm">无异议并打印</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import obj from '@iebdc/router/modules/my-query.js';
import utils from '@/utils/utils';
import {
  printQszmXml,
  printQszm,
  printYfqszm,
  printWfqszm,
  getFjclByYwh,
  getQszmHtml,
  reviewFwcd,
  printQszmWithSignature,
} from '@iebdc/api/my-query.js';
import { getNewFwCdzmDetail } from '@iebdc/api/qszmcx-service';
import applicantFinishService from '@/pages/iebdc/api/apply/applicant-finish';
import { localDataUser } from '@iebdc/utils/local-data';
import viewAttachments from './view-attachments';
import { cloneDeep } from 'lodash';

export default {
  name: 'queryResult',
  components: {
    viewAttachments,
  },
  props: {
    checkData: {
      type: Object,
      default() {
        return {};
      },
    },
    //查询参数
    qszmFormData: {
      type: Object,
      default() {
        return {};
      },
    },
    cxmd: {
      type: String,
      default() {
        return '';
      },
    },
    totalNum: {
      type: Number,
      default() {
        return 0;
      },
    },
    nCurrent: {
      type: Number,
      default() {
        return 0;
      },
    },
    cxjgData: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      tableList: [],
      loading: false,
      previewVisiable: false,
      objPath: obj.path,
      nowDate: '',
      cxbh: '',
      qszmHtml: '',
      userName: localDataUser.get().name,
      lxdh: localDataUser.get().tel || localDataUser.get().frdh,
      zjhm: localDataUser.get().qyzjh || localDataUser.get().zjh,
      columns: [
        {
          label: '不动产权证书（登记证明）号',
          prop: 'bdcqzh',
          width: '280px',
        },
        {
          label: '坐落',
          prop: 'zl',
          width: '300px',
          formatter: (scope) => {
            return scope.zl ? scope.zl : scope.houseLocation;
          },
        },
        // {
        //   label: '业务号',
        //   prop: 'ywh',
        //   width: '150',
        //   formatter: (scope) => {
        //     return scope.ywh? scope.ywh: scope.taskNo;
        //   }
        // },
        // {
        //   label: '是否有产权',
        //   prop: 'sfbdc',
        //   width: '120',
        //   formatter: (scope) => {
        //     if (scope.sfbdc === '1') {
        //       return '是';
        //     } else {
        //       return '否';
        //     }
        //   }
        // },
        {
          label: '是否抵押',
          prop: 'sfdy',
          formatter: (scope) => {
            if (scope.sfdy !== '未检索到抵押信息') {
              return '是';
            } else {
              return '否';
            }
          },
        },
        {
          label: '是否查封',
          prop: 'sfcf',
          formatter: (scope) => {
            if (scope.sfcf !== '未检索到查封信息') {
              return '是';
            } else {
              return '否';
            }
          },
        },
        // {
        //   label: '是否预告',
        //   prop: 'sfyg',
        //   formatter: (scope) => {
        //     if (scope.sfyg === '1') {
        //       return '是';
        //     } else {
        //       return '否';
        //     }
        //   }
        // },
        // {
        //   label: '是否预告抵押',
        //   prop: 'sfygdy',
        //   width: '110px',
        //   formatter: (scope) => {
        //     if (scope.sfygdy === '1') {
        //       return '是';
        //     } else {
        //       return '否';
        //     }
        //   }
        // },
        // {
        //   label: '是否异议',
        //   prop: 'sfyy',
        //   formatter: (scope) => {
        //     if (scope.sfyy === '1') {
        //       return '是';
        //     } else {
        //       return '否';
        //     }
        //   }
        // }
      ],
      options: {
        pageSize: 1000,
        loading: true,
        hasPagination: false,
        maxHeight: '556px',
        mutiSelectAndSinglechoice: true, //实现element多选操作的单选
        highlightCurrentRow: false,
      },
      // 当前页码
      currentPage: 1,
      total: 0,
      selectedBdcxx: {},
      isDisabled: false, //查档,有房,无房按钮是否禁用
      nextSelectAreaCode: '', //保存上一次查询的结果
      //查看附件
      ckfjData: {},
      houseNum: 0,
      fileImgUrl: '', //登记系统映射的房查附件地址
      houseList: [], //住宅用途数据
    };
  },
  computed: {
    ...mapGetters(['selectedBsdtCode']),
    ...mapState('dashboard', ['selectedAreaTitle', 'selectedShiTitle', 'selectedAreaCode', 'bsdtDialogVisible']),
  },
  watch: {
    selectedAreaCode() {
      // 当大厅弹窗关闭时,给出提示信息
      if (!this.bsdtDialogVisible) {
        if (this.nextSelectAreaCode == this.selectedAreaCode) return;
        this.isDisabled = true;
        this.nextSelectAreaCode = this.selectedAreaCode;
      }
    },
    nCurrent: {
      deep: true,
      immediate: true,
      handler() {
        console.log(this.nCurrent, this.currentPage);
        this.currentPage = this.nCurrent;
      },
    },
  },
  methods: {
    backHomepage() {
      this.$router.go(-1);
    },
    //已选房产信息
    currentChangeHandle(val) {
      this.selectedBdcxx = val;
      console.log(val);
    },
    getDicValue(label, value) {
      return this.$store.getters.getLabelDicByTypeAndValue(label, value);
    },
    showtQszm() {
      // if (!this.checkData.bdcxxList) {
      //   this.$message.warning('当前无房产信息');
      //   return;
      // }
      this.loading = true;
      //当只有一套房产信息  默认选中当前房产
      if (this.checkData.bdcxxList.length && this.checkData.bdcxxList.length === 1) {
        this.selectedBdcxx = this.checkData.bdcxxList[0];
      }
      if (this.selectedBdcxx && this.selectedBdcxx.bdcqzh) {
        // getNewFwCdzmDetail({
        //   loginName: localDataUser.get().loginName || localDataUser.get().userName,
        //   cxType: 'CDZM',
        //   cxlx: this.qszmFormData.cxlx,
        //   params: [
        //     {
        //       bdcdyh: this.selectedBdcxx.bdcdyh,
        //       bdcqzh: this.selectedBdcxx.bdcqzh,
        //       qlrmc: this.selectedBdcxx.qlrmc,
        //       qlrzjh: this.selectedBdcxx.zjh,
        //       fwid: this.selectedBdcxx.fwzdid || '',
        //       qydm: this.$store.state.dashboard.selectedAreaCode,
        //       ywh: this.selectedBdcxx.ywh
        //     }
        //   ],
        //   uploadFileRecords: this.qszmFormData.fileData
        // })
        //   .then((res) => {
        //     if (res.data && res.success) {
        //       let cfxxs = '';
        //       let dyxxs = '';
        //       // if (res.data.cfdetails && res.data.cfdetails.length) {
        //       //   res.data.cfdetails.forEach((cfxx) => {
        //       //     cfxxs += `${cfxx.qlrmc}；查封机关：${cfxx.cfjg}；查封文件：${cfxx.cfwj}；查封类型：${cfxx.cflxmc}；查封文号：${cfxx.cfwh}；登记时间：${cfxx.djsj}；查封起始日期：${cfxx.cfqssj}；查封终止日期：${cfxx.cfjssj}；被查封证号：${cfxx.bdcqzh}；`;
        //       //   });
        //       // }
        //       // if (res.data.dydetails && res.data.dydetails.length) {
        //       //   res.data.dydetails.forEach((dyxx) => {
        //       //     dyxxs += ` 抵押方式：${dyxx.dyfs}；是否预告抵押：${dyxx.ygdydetails}；抵押权人：${dyxx.dyqr}；抵押人：${dyxx.dyr}；不动产证明号：${dyxx.bdcdjzmh}；登记时间：${dyxx.djsj}；起始日期：${dyxx.qssj}；终止日期：${dyxx.jssj}；抵押金额：${dyxx.dyje}${dyxx.jedw}；`;
        //       //   });
        //       // }

        //       this.cxbh = res.data.cxbh;
        //       this.cdzmParams = res.data.cxjg[0].fwcdDetail[0];
        //       this.cdzmParams.officeCode = this.selectedBsdtCode;
        //       // this.cdzmParams.officeCode.qlxz=thi
        //       this.cdzmParams.gyfs = this.getDicValue('共有方式', this.cdzmParams.gyfs);
        //       this.cdzmParams.tdxz = this.getDicValue('权利性质', this.cdzmParams.tdxz);
        //       this.cdzmParams.smc = this.selectedAreaTitle;
        //       this.cdzmParams.cfxxDetails = this.cdzmParams.cfdetails;
        //       this.cdzmParams.cfxx = res.data.cfxx;
        //       this.cdzmParams.dyxx = res.data.dyxx;
        //       this.cdzmParams.loginName = localDataUser.get().loginName || localDataUser.get().userName;
        //       // this.cdzmParams.zmbh = this.checkData.cxbh;
        //       this.cdzmParams.fwzmbh = this.cdzmParams.zmbh;
        //       getQszmHtml(this.cdzmParams).then((res) => {
        //         if (res) {
        //           this.previewVisiable = true;
        //           this.qszmHtml = res.data;
        //         }
        //       });
        //     } else {
        //       this.$message.warning('下载失败!');
        //     }
        // })
        // .finally(() => {
        //   this.loading = false;
        // });
        printQszmXml({ cxbh: this.selectedBdcxx.cxbh }).then((res) => {
          if (res.success) {
            if (res.data) {
              this.previewVisiable = true;
              this.qszmHtml = res.data;
            }
          } else {
            this.$message.warning('暂无权属证明!');
          }
        });
      } else {
        printQszmWithSignature({
          cxbh: this.checkData.receiveSeqNo,
          loginName: localDataUser.get().loginName,
          sign: 1,
        }).then((res) => {
          // 202008191735141196
          if (res.data && res.success) {
            window.open(res.data);
            this.loading = false;
          } else {
            this.$message.warning('下载失败!');
            this.loading = false;
          }
        });
      }
    },
    printQszm() {
      // printQszm(this.cdzmParams).then(
      //   (res) => {
      //     if (res.success && res.data) {
      //       window.open(res.data);
      //     } else {
      //       this.$message.warning('下载失败!');
      //     }
      //   },
      //   () => {
      //     this.$message.warning('下载失败!');
      //   }
      // );
      printQszmWithSignature({ cxbh: this.selectedBdcxx.cxbh, sign: 1 }).then((res) => {
        // 202008191735141196
        if (res.data && res.success) {
          window.open(res.data);
        } else {
          this.$message.warning('下载失败!');
        }
      });
    },
    //查看附件具体详情
    checkDetail() {
      this.loading = true;
      //当只有一套房产信息  默认选中当前房产
      // if (this.checkData.bdcxxList.length === 1) {
      //   this.selectedBdcxx = this.checkData.bdcxxList[0];
      // }
      this.ckfjData = $.extend(true, {}, this.selectedBdcxx);
      getFjclByYwh({
        ywh: this.ckfjData.ywh.substring(0, this.ckfjData.ywh.length - 2),
        qydm: this.selectedAreaCode,
      }).then((res) => {
        if (res.data && res.success) {
          this.$set(this.ckfjData, 'fileData', res.data.bizFile);
          this.$set(this.ckfjData, 'ckfjDialogVisible', true);
        } else {
          this.$message.warning('下载失败!');
        }
      });
    },
    //查看附件
    async watchFJgl() {
      if (!this.checkData.bdcxxList) {
        this.$message.warning('当前无房产信息');
        return;
      }
      if (!this.selectedBdcxx || !this.selectedBdcxx.bdcqzh) {
        this.$message.warning('请选择一条房屋信息');
        return false;
      }
      //获取登记系统附件映射地址
      const { success, data } = await applicantFinishService.getBsdtDetail({ officeCode: this.selectedBsdtCode });
      if (success && data) {
        //配置了公示公告外链则直接跳转
        if (data.fcfjurl && data.fcfjurl != '') {
          this.fileImgUrl = data.fcfjurl;
          this.checkDetail();
        } else {
          this.$message.warning('暂无法进行查看，请先到大厅管理配置房产查询附件映射地址！');
          return false;
        }
      } else {
        this.$message.warning(data.message);
      }
    },
    changeckfjData(val) {
      // console.log(val)
    },
    verifyQszm() {
      const _this = this;
      this.$prompt('请填写异议内容', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning', // 图标样式 "warning"|"error"...
        inputValue: '',
        inputErrorMessage: '异议内容不能为空',
        inputValidator: (value) => {
          // 点击按钮时，对文本框里面的值进行验证
          if (!value) {
            return '异议内容不能为空';
          }
        },
      })
        .then(({ value }) => {
          reviewFwcd({
            cxbh: _this.selectedBdcxx.cxbh,
            yymsg: value,
            shjg: false,
            loginName: localDataUser.get().loginName,
            cxjg: this.cxjgData,
          }).then((res) => {
            if (res.success) {
              _this.$message.success('提交成功，请等待业务人员审核后查看');
              _this.previewVisiable = false;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //打印有房证明
    printYfqszm() {
      console.log(this.selectedBdcxx);
      if (this.houseList.length < 1) {
        this.$message.warning('当前无房产信息');
        return;
      }
      if (!this.selectedBdcxx || !this.selectedBdcxx.bdcqzh) {
        this.$message.warning('请选择一条房屋信息');
        return false;
      }
      if (this.selectedBdcxx.sfbdc !== '1') {
        this.$message.warning('当前选择房产暂无产权');
      }
      let cxmd = this.$store.getters.getLabelDicByTypeAndValue('查档用途', this.cxmd);
      if (!cxmd) {
        this.$message.warning('请选择查询目的');
        return false;
      } else {
        let paramsData = [];
        const copyData = cloneDeep(this.selectedBdcxx);
        paramsData.push(copyData);
        printYfqszm({
          cxrmc: this.selectedBdcxx.qlrmc || '',
          zjh: this.selectedBdcxx.zjh || '',
          officeCode: this.selectedBsdtCode,
          loginName: localDataUser.get().loginName,
          smc: this.selectedAreaTitle,
          cxmd: cxmd,
          params: paramsData,
        }).then((res) => {
          if (res.data && res.success) {
            window.open(res.data);
          } else {
            this.$message.warning('下载失败!');
          }
        });
      }
    },
    //打印无房证明
    printWfqszm() {
      printWfqszm({
        cxrmc: this.qszmFormData.bcxr,
        qlr: this.qszmFormData.bcxr,
        qlrzjh: this.qszmFormData.bcxrzjh,
        officeCode: this.selectedBsdtCode,
        qymc: this.selectedAreaTitle,
        smc: this.selectedAreaTitle,
      }).then((res) => {
        if (res.data && res.success) {
          window.open(res.data);
        } else {
          this.$message.warning('下载失败!');
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.$emit('getListData', val);
    },
    // 无异议打印
  },
  filters: {
    filterTime: function (val) {
      return val ? utils.dateFtt(val, 'yyyy年MM月dd日') : '';
    },
  },
  created() {
    this.houseNum = 0;
    if (this.checkData.bdcxxList && this.checkData.bdcxxList.length > 0) {
      //有房则展示房产信息列表
      this.tableList = this.checkData.bdcxxList;
      this.houseNum = this.checkData.bdcxxList.length;
      this.houseList = this.checkData.bdcxxList;
      // this.checkData.cxbh = this.checkData.bdcxxList[0].zmbh;
      // this.tableList.map((item) => {
      //   this.$set(item, 'checked', false);
      //   if (item.qszt === '1') {
      //     this.houseNum++;
      //   }
      //   this.checkData.isyf = true;
      //   this.houseList.push(item);
      // });
    }
    this.nowDate = utils.getNowDate(1);
  },
};
</script>

<style lang="scss" scoped>
@import '~@/pages/iebdc/styles/public.scss';
@import '~@/pages/iebdc/styles/common-variables.scss';

.bgcolorTr {
  background: rgb(242, 246, 252);
  text-align: center;
  width: 200px;
}
.mt30 {
  margin-top: 30px;
}
.jzxx {
  text-align: center;
}
.complete {
  .title {
    margin-top: $spacing-large 0;
    width: 100%;
    height: 46px;
    font-weight: bolder;
    font-size: 18px;
    color: #303133;
    position: relative;
    span {
      line-height: 46px;
      display: inline-block;
      position: relative;
      &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #2c8bff;
        content: '';
      }
    }
    div {
      position: absolute;
      right: 120px;
      top: 0;
    }
  }
  .remind {
    color: #303133;
    margin: $spacing-base 0;
    .font-bold {
      font-weight: bold;
    }
  }
  .block {
    text-align: center;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    tr td {
      height: 36px;
      border: 1px solid #e0e0e0;
    }
    tr th {
      height: 36px;
      border: 1px solid #e0e0e0;
    }
  }
  .tableData > tr > td:nth-child(2n-1) {
    text-align: center;
    width: 20%;
    word-break: break-all;
  }
  .tableData > tr > td:nth-child(2n) {
    text-align: center;
    width: 20%;
    word-break: break-all;
  }
  .wt-4 {
    word-break: break-all;
    width: 50px;
  }
  .table2 {
    tr:last-child {
      background: white !important;
    }
    .wt-7 {
      width: 7% !important;
      word-break: break-all;
    }
    .wt-12 {
      width: 12% !important;
      word-break: break-all;
    }
    .wt-13 {
      width: 13% !important;
      word-break: break-all;
    }
    .wt-14 {
      width: 14% !important;
      word-break: break-all;
    }
    .wt-16 {
      width: 16% !important;
      word-break: break-all;
    }
    .wt-17 {
      width: 17% !important;
      word-break: break-all;
    }
    .wt-18 {
      width: 18% !important;
      word-break: break-all;
    }
    .wt-20 {
      width: 20% !important;
      word-break: break-all;
    }
  }
}
/deep/ .el-table__body tr.current-row > td {
  background-color: #dcdcdc !important;
}
</style>
